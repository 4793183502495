import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, Input, Button, Select, DatePicker } from "antd";
import { CaretDownFilled } from '@ant-design/icons'
import { SCHOOLS, SERVICE_TIME_SPANS } from '../assets/constants'; 
import { useContent } from '../hooks/useContent';
import { CertificateGenerator } from "./../CertificateGenerator";
import BadgeContainer from '../components/BadgeContainer';

const CertificateForm = (props) => {
    const { Option } = Select;
    const navigate = useNavigate();
    const { badgeIcon, useForm } = props;
    const { state, updateContent, certType } = useContent((state) => ({ 
        state: state.getContent(),
        updateContent: state.updateState,
        certType: state.certificateType
    }));

    const defaultStyle = {
        opacity: 1,
    };

    var formTitle = "Générateur de certificat de reconnaissance";

    if (certType === "bilinguisme") {
        formTitle = "Générateur de certificat de bilinguisme";
    }

    const updateState = (props) => {
        updateContent(props)
    }

    const handleFormSubmit = () => {
        CertificateGenerator(certType, state.recipientName)
        navigate("/confirmation")
    }

    return (
        <div className="panel">
            <BadgeContainer 
                badgeIcon={ certType === "bilinguisme" ? badgeIcon[0] : badgeIcon[1] }
                defaultStyle={defaultStyle} 
                // transitionStyles={transitionStyles}
            ></BadgeContainer>

            <p className="h4">{ formTitle }</p>

            { certType === "bilinguisme" && 
                <Form
                    form={useForm}
                    layout="vertical"
                    // initialValues={{
                    //     requiredMarkValue: requiredMark,
                    // }}
                    onValuesChange={updateState}
                    onFinish={handleFormSubmit}
                    style={{
                        ...defaultStyle,
                        // ...transitionStyles[state],
                    }}
                >
                    <Form.Item
                    label="Nom de l’élève"
                    name="recipientName"
                    rules={[
                        {
                        required: true,
                        message: "Ce champ est nécessaire.",
                        },
                    ]}
                    >
                        <Input placeholder="Nom du destinataire" />
                    </Form.Item>

                    <Form.Item
                    label="École"
                    name="activeSchool"
                    rules={[
                        {
                        required: true,
                        message: "Ce champ est nécessaire.",
                        },
                    ]}
                    >
                        <Select suffixIcon={<CaretDownFilled />} placeholder="" optionFilterProp="">
                            {SCHOOLS.map((school, index) => (
                                <Option key={"school-" + index} value={index}>{school.schoolIndex}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                    label="Date"
                    name="date"
                    rules={[
                        {
                        required: true,
                        message: "Ce champ est nécessaire.",
                        },
                    ]}
                    >
                        <DatePicker suffixIcon={null} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Générer le certificat
                        </Button>
                    </Form.Item>
                </Form>
            }

            { certType === "reconnaissance" && 
                <Form
                    form={useForm}
                    layout="vertical"
                    // initialValues={{
                    //     requiredMarkValue: requiredMark,
                    // }}
                    onValuesChange={updateState}
                    onFinish={handleFormSubmit}
                    style={{
                        ...defaultStyle,
                        // ...transitionStyles[state],
                    }}
                >
                    <Form.Item
                        label="Nom de l’employé"
                        name="recipientName"
                        rules={[
                            {
                                required: true,
                                message: "Ce champ est nécessaire.",
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>

                    <Form.Item
                    label="Nombre d'années de service"
                    name="activeServiceTimeSpan"
                    rules={[
                        {
                            required: true,
                            message: "Ce champ est nécessaire.",
                        },
                    ]}
                    >
                        <Select suffixIcon={<CaretDownFilled />} placeholder="" optionFilterProp="">
                            {SERVICE_TIME_SPANS.map((timespan, index) => (
                                <Option key={"timespan-" + index} value={index}>{timespan.text}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                    label="Date"
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: "Ce champ est nécessaire.",
                        },
                    ]}
                    >
                        <DatePicker suffixIcon={null} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Générer le certificat
                        </Button>
                    </Form.Item>
                </Form>
            }
        </div>
    );
};
  
export default CertificateForm;