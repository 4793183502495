import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const CertificateGenerator = function(certType, recipientName) {
    var element = document.getElementById("cert");

    window.scrollTo(0, 0);

    html2canvas(element, {
        scale: 4,
    }).then(function (canvas) {
        var imgData = canvas.toDataURL("image/jpeg");
        var doc = new jsPDF({ orientation: "l", format: "letter" });
        doc.addImage(imgData, "jpeg", 0, 0, 280, 216);
        doc.save(
            "CSPNE_Certificat" + certType + "_" + recipientName.replace(/ /g, "_") + ".pdf"
        );
        // document.body.appendChild(canvas); // DEBUG

        window.scrollTo(0, 0);

        return true;
    });
};

export { CertificateGenerator };