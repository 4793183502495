import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from "antd";
import hash from "object-hash";

import { useAuth } from "../hooks/useAuth";
import badgeIcon from "../assets/images/lock.svg";
import BadgeContainer from '../components/BadgeContainer';

const Login = () => {
    const [useForm] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState("optional");
    const navigate = useNavigate();
    const authenticate = useAuth((state) => state.authenticate);

    const defaultStyle = {
        // transition: `opacity ${duration}ms ease-in-out`,
        // opacity: 0,
        opacity: 1,
    };

    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    // Check Password
    const checkPassword = async (values) => {
        const isAuthed = await authenticate(hash.MD5(values.password))

        if (isAuthed) {
            navigate("/certificate-selection")
        } else {
            useForm.setFields([
                {
                    name: "password",
                    errors: ["Mot de passe incorrect."],
                },
            ]);
        }
    };

    return (
        <>
            <div className="panel">
                <BadgeContainer 
                    badgeIcon={ badgeIcon }
                    // transitionStyles={transitionStyles}
                ></BadgeContainer>

                <p className="h4">Générateurs de Certificats</p>

                <Form
                    form={useForm}
                    layout="vertical"
                    initialValues={{
                        requiredMarkValue: requiredMark,
                    }}
                    onValuesChange={onRequiredTypeChange}
                    onFinish={checkPassword}
                    style={{
                        ...defaultStyle
                        // ...transitionStyles[state]
                    }}
                >
                    <Form.Item
                        label="Mot de passe"
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: "Mot de passe incorrect.",
                        },
                        ]}
                    >
                        <Input.Password></Input.Password>
                    </Form.Item>
                    
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Accéder aux générateurs
                        </Button>
                    </Form.Item>
                </Form>
            </div>

            <div className="notification">
                <p>Veuillez noter que les générateurs de certificats est optimisés pour une utilisation sur ordinateur de bureau.</p>
            </div>
        </>
    );
};
  
export default Login;