// School Logos
import nipissing from "./images/schools/nipissing.svg";
import echo_du_nord from "./images/schools/echo_du_nord.svg";
import passeport_jeunesse from "./images/schools/passeport_jeunesse.svg";
import odyssee from "./images/schools/publique.svg";
import renaissance from "./images/schools/renaissance.svg";
import EcolepubliqueauxQuatreVents from "./images/schools/EcolepubliqueauxQuatreVents.svg";
import Cano from "./images/schools/CANO.svg";

export const SCHOOLS = [
    {
        schoolIndex: "École secondaire publique Nipissing Ouest",
        location: "Sturgeon Falls",
        logo: nipissing,
        hex: "#00A99A",
        nameSchoolDirection: "Suzanne Hevey",
        titleSchoolDirection: "Directrice d'école",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
    },
    {
        schoolIndex: "École secondaire publique Odyssée",
        location: "North Bay",
        logo: odyssee,
        hex: "#2155A2",
        nameSchoolDirection: "Mathieu Perron",
        titleSchoolDirection: "Directeur d'école",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
    },
    {
        schoolIndex: "École publique Renaissance",
        location: "Timmins",
        logo: renaissance,
        hex: "#5A2C82",
        nameSchoolDirection: "Roch Mageau",
        titleSchoolDirection: "Directeur d'école",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
    },
    {
        schoolIndex: "École publique Passeport Jeunesse",
        location: "Hearst",
        logo: passeport_jeunesse,
        hex: "#F27224",
        nameSchoolDirection: "Isabelle Boucher",
        titleSchoolDirection: "Directrice d'école",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
    },
    {
        schoolIndex: "École secondaire publique Écho du Nord",
        location: "Kapuskasing",
        logo: echo_du_nord,
        hex: "#BE1F42",
        nameSchoolDirection: "Yanik Boudreau",
        titleSchoolDirection: "Directeur d'école",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
    },
    {
        schoolIndex: "École secondaire publique aux Quatre-Vents",
        location: "Parry Sound",
        logo: EcolepubliqueauxQuatreVents,
        hex: "#2255A0",
        nameSchoolDirection: "Joanne Héroux Farrow",
        titleSchoolDirection: "Directrice d'école",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
    },
    {
        schoolIndex: "CANO Kapuskasing",
        location: "Kapuskasing",
        logo: Cano,
        hex: "#002169",
        nameSchoolDirection: "Yanik Boudreau",
        titleSchoolDirection: "Directeur d'école",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
    },
];

export const SERVICE_TIME_SPANS = [
    {
        value: 5,
        text: "5 années",
        hex: "#2155A2",
        nameSchoolDirection: "Denis Labelle",
        titleSchoolDirection: "Président",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
        certificateBadge: "star",
    },
    {
        value: 10,
        text: "10 années",
        hex: "#2155A2",
        nameSchoolDirection: "Denis Labelle",
        titleSchoolDirection: "Président",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
        certificateBadge: "star",
    },
    {
        value: 15,
        text: "15 années",
        hex: "#2155A2",
        nameSchoolDirection: "Denis Labelle",
        titleSchoolDirection: "Président",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
        certificateBadge: "star",
    },
    {
        value: 20,
        text: "20 années",
        hex: "#2155A2",
        nameSchoolDirection: "Denis Labelle",
        titleSchoolDirection: "Président",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
        certificateBadge: "star",
    },
    {
        value: 25,
        text: "25 années",
        hex: "#EFAF1D",
        nameSchoolDirection: "Denis Labelle",
        titleSchoolDirection: "Président",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
        certificateBadge: "25-year-badge",
    },
    {
        value: 30,
        text: "30 années",
        hex: "#EFAF1D",
        nameSchoolDirection: "Denis Labelle",
        titleSchoolDirection: "Président",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
        certificateBadge: "30-year-badge",
    },
    {
        value: 35,
        text: "35 années",
        hex: "#EFAF1D",
        nameSchoolDirection: "Denis Labelle",
        titleSchoolDirection: "Président",
        nameEducationDirecteur: "Yves Laliberté",
        titleEducationDirecteur: "Directeur de l'éducation",
        certificateBadge: "35-year-badge",
    },
];

export const OPTIONS = {
    password: "d1b5b439ca9a47a8d69502bb6fa4c37c",
};

export const MONTHS = [
    "de janvier",
    "de février",
    "de mars",
    "d'avril",
    "de mai",
    "de juin",
    "de juillet",
    "d'août",
    "de septembre",
    "d'octobre",
    "de novembre",
    "de décembre",
];
