import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Form, Button } from "antd";
import { useContent } from '../hooks/useContent';

import BadgeContainer from '../components/BadgeContainer'

const CertificateConfirmation = (props) => {
    const { badgeIcon, useForm } = props;
    const certType = useContent((state) => state.certificateType);
    
    const recipientName = useContent((state) => state.getContent().recipientName);
    const navigate = useNavigate();

    const [requiredMark, setRequiredMarkType] = useState("optional");
    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const defaultStyle = {
        // transition: `opacity ${duration}ms ease-in-out`,
        // opacity: 0,
        opacity: 1,
    };

    var confirmationText = "Le certificat de " + recipientName + " a été créé et a été téléchargé dans le dossier de Téléchargement de votre ordinateur.";
    var buttonText = "Générer un autre certificat de reconnaissance";
    if (certType === "bilinguisme") {
        buttonText = "Générer un autre certificat de bilinguisme";
    }

    const handleReset = () => {
        navigate("/form");
        useForm.resetFields();
    };

    return (
        <div className="panel">
            <BadgeContainer 
                badgeIcon={ badgeIcon }
                defaultStyle={defaultStyle}
                // transitionStyles = {transitionStyles}
            ></BadgeContainer>

            <p className="h4">Succès!</p>

            <Form
                form={ useForm }
                layout="vertical"
                initialValues={{
                  requiredMarkValue: requiredMark,
                }}
                onValuesChange={onRequiredTypeChange}
                onFinish={ handleReset }
                style={{
                  ...defaultStyle,
                  // ...transitionStyles[state],
                }}
            >

                <p>{ confirmationText }</p>

                <Form.Item>
                    <Button type="primary" htmlType="submit">{ buttonText }</Button>
                </Form.Item>
            </Form>
        </div>
    );
};
  
export default CertificateConfirmation;