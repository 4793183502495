import { create } from "zustand"

import { SCHOOLS, SERVICE_TIME_SPANS } from "../assets/constants"

export const useContent = create((set, get) => ({
    certificateType: null, // bilinguisme | reconnaissance 
    schools: { ...SCHOOLS },
    serviceTimeSpans: { ...SERVICE_TIME_SPANS},
    activeSchool: null,
    activeServiceTimeSpan: null,
    recipientName: null,
    date: null,
    updateState: (newState) => set((state) => ({ ...state, ...newState })),
    updateCertType: (certType) => set((state) => ({ ...state, certificateType: certType })),
    getContent: () => {
        if(get().certificateType === 'bilinguisme'){
            return {
                recipientName: get().recipientName,
                activeSchool: get().activeSchool, 
                school: get().schools[get().activeSchool],
                date: get().date,
            }
        } else if(get().certificateType === 'reconnaissance'){
            return {
                recipientName: get().recipientName,
                activeServiceTimeSpan: get().activeServiceTimeSpan,
                serviceTimeSpan: get().serviceTimeSpans[get().activeServiceTimeSpan],
                date: get().date,
            }
        }
    },
}))