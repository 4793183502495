import React from "react";
import { useNavigate } from "react-router-dom";
import { useContent } from "../hooks/useContent";

import BadgeContainer from '../components/BadgeContainer';

const CertificateSelection = (props) => {
    const { badgeIcon } = props;
    const navigate = useNavigate();
    const updateCertType = useContent((state) => state.updateCertType);

    const buttonText = "Accéder au générateur";
    const defaultStyle = {
        opacity: 1,
    };

    return (
        <div className="centered">
            <div className="panel inline">
                <BadgeContainer 
                    badgeIcon={ badgeIcon[0] }
                    defaultStyle={defaultStyle} 
                    // transitionStyles={transitionStyles}
                ></BadgeContainer>

                <p className="h4">Certificat de bilinguisme</p>

                <button className="ant-btn ant-btn-primary"
                    onClick={() => {
                        updateCertType("bilinguisme");
                        navigate("/form");
                    }}
                >
                    {buttonText}
                </button>
            </div>

            <div className="panel inline">
                <BadgeContainer 
                    badgeIcon={ badgeIcon[1] }
                    defaultStyle={defaultStyle} 
                    // transitionStyles={transitionStyles}   
                ></BadgeContainer>

                <p className="h4">Certificat de reconnaissance</p>

                <button className="ant-btn ant-btn-primary"
                    onClick={() => {
                        updateCertType("reconnaissance");
                        navigate("/form");
                    }}
                >
                    {buttonText}
                </button>
            </div>
        </div>
    );
};
  
export default CertificateSelection;