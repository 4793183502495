import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { OPTIONS } from "../assets/constants";

export const useAuth = create(
    persist(
        (set, get) => ({
            password: OPTIONS.password,
            isAuthenticated: false,
            authenticate: (pass) => {
                if (pass === get().password) {
                    set({ isAuthenticated: true });
                    return true;
                } else {
                    set({ isAuthenticated: false });
                    return false;
                }
            },
        }),
        {
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);
