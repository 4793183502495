import { useContent } from '../../hooks/useContent';

import BilingualCertificate from "./BilingualCertificate";
import YearsOfServiceCertificate from "./YearsOfServiceCertificate";

const CertificateProvider = () => {
    const certType = useContent((state) => state.certificateType);

    switch(certType) {
        case 'bilinguisme':
            return <BilingualCertificate />
        case 'reconnaissance':
            return <YearsOfServiceCertificate />
        default:
            return null;
    }
}

export default CertificateProvider;