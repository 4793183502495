import React  from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Form } from "antd";

import "normalize.css";
import "antd/dist/antd.min.css";
import './assets/css/App.scss';

import PageLayout from "./pages/PageLayout";
import Login from "./pages/Login";
import CertificateSelection from "./pages/CertificateSelection";
import CertificateForm from "./pages/CertificateForm";
import CertificateConfirmation from "./pages/CertificateConfirmation";
import CertificateProvider from "./components/certificates";

// images
import flag from "./assets/images/flag.svg";
import star from "./assets/images/star.svg";
import checkmark from "./assets/images/checkmark.svg";

function App() {
  const [form] = Form.useForm();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PageLayout />}>
            <Route index element={<Login />}></Route>
            <Route path="certificate-selection" element={ <CertificateSelection badgeIcon={ [flag, star] } /> }></Route>
            <Route path="form" element={ <CertificateForm badgeIcon={ [flag, star] } useForm={ form } /> }></Route>
            <Route path="confirmation" element={ <CertificateConfirmation badgeIcon={ checkmark } useForm={ form } /> }></Route>
          </Route>
        </Routes>
      </BrowserRouter>

      <CertificateProvider />
    </>
  );
}

export default App;