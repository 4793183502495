import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { useAuth } from "./../hooks/useAuth";

// images
import logo from "../assets/images/logo.svg";

const PageLayout = () => {
  const { Content, Footer } = Layout;
  const isAuthed = useAuth((state) => state.isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if(!isAuthed){
      navigate("/");
    } else {
      if(window.location.pathname !== "/certificate-selection"){
        navigate("/certificate-selection");
      }
    }
    // We want this to only run when page is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const footerStyle = {
    backgroundColor: 'unset',
    display: 'block',
    margin: 'auto',
    marginBottom: '8rem',
    maxWidth: '100%'
  };

  return (
    <>
      <div className="App">
        <Layout className="Layout">

          <header className="App-header"></header>

          <Content>
            <Outlet />
          </Content>

          <Footer style={footerStyle}>
            <img src={logo} className="App-logo" alt="CSPNE" />
          </Footer>
        </Layout>
      </div>
    </>
  )
};

export default PageLayout;