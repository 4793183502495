// images
import badge from "../assets/images/badge.svg";

function BadgeContainer(props) {
    const {badgeIcon, defaultStyle, transitionStyles} = props;

    return (
        <div className="badgeContainer">
            <img src={badge} alt="badge" />
            <img
                src={badgeIcon}
                className="icon"
                style={{
                  ...defaultStyle,
                  ...transitionStyles
                }}
                alt="Icon"
            />
        </div>
    );
};
  
export default BadgeContainer;