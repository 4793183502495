import { useContent } from "./../../hooks/useContent";
import { MONTHS } from "../../assets/constants";
import moment from "moment";

import consellogo from "./../../assets/images/conseil-logo-stacked.svg";
import star from "./../../assets/images/star.svg";
import yosbadge_25 from "./../../assets/images/25-year-badge.svg";
import yosbadge_30 from "./../../assets/images/30-year-badge.svg";
import yosbadge_35 from "./../../assets/images/35-year-badge.svg";
import BadgeContainer from "../BadgeContainer";
import signatureDenisLabelle from "./../../assets/images/signature_Denis-Labelle.png";
import signatureYvesLaliberte from "./../../assets/images/signature_Yves-Laliberte.png";

const YearsOfServiceCertificate = () => {
    const defaultStyle = {
        // transition: `opacity ${duration}ms ease-in-out`,
        // opacity: 0,
        opacity: 1,
    };

    const state = useContent((state) => state.getContent());

    if(state.activeServiceTimeSpan === null) return null;

    // const badgeIcon = star;
    var badgeIcon = null;
    switch (state.serviceTimeSpan.value) {
        case 25:
            badgeIcon = yosbadge_25;
            break;
        case 30:
            badgeIcon = yosbadge_30;
            break;
        case 35:
            badgeIcon = yosbadge_35;
            break;
        default:
            badgeIcon = star;
            break;
    }

    return (
        <div id="yos-certificate" className="certificate-container">
            <div id="cert">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    strokeLinejoin="round"
                    strokeMiterlimit="2"
                    clipRule="evenodd"
                    width="792"
                    height="612"
                    viewBox="0 0 792 612"
                >
                    <g fill={state.serviceTimeSpan.hex}>
                        <path d="M744 36v8h3v-4h5v5h-4v3h8v516.8h-8v3h4v5h-5v-4h-3v8H47v-8h-3v4h-5v-5h4v-3h-8V48h8v-3h-4v-5h5v4h3v-8h331.788l-.882 1H48v8h-4v4h-8v514.8h8v4h4v8h695v-8h4v-4h8V49h-8v-4h-4v-8H412.094l-.882-1H744zM43 568.8h-3v3h3v-3zm708 0h-3v3h3v-3zM751 41h-3v3h3v-3zM43 41h-3v3h3v-3z"></path>
                    </g>
                </svg>

                { state.serviceTimeSpan.value <= 20 ? 
                    <BadgeContainer 
                        badgeIcon={ badgeIcon }
                        defaultStyle={defaultStyle}
                        // transitionStyles = {transitionStyles}
                    ></BadgeContainer> 
                :
                    <img
                        src={badgeIcon}
                        className="badge"
                        style={{
                            ...defaultStyle,
                            // ...transitionStyles
                        }}
                        alt="Icon"
                    />
                }

                <div id="certtext" className={ state.serviceTimeSpan.value > 20 ? "is-over-20" : "" }>
                    <h1>
                        Certificat de reconnaissance <br />
                        de {state.serviceTimeSpan.value} années de service
                    </h1>
                    
                    <p style={{ textAlign: "center" }}>
                    Cette reconnaissance est décernée à
                    </p>

                    <div className="studentName">{state.recipientName}</div>

                    <p style={{ textAlign: "center" }}>
                    afin de souligner son engagement et son dévouement au sein de la grande
                    <br />
                    équipe du Conseil scolaire public du Nord-Est de l'Ontario. Félicitations!
                    </p>

                    <p style={{ textAlign: "center" }}>
                    Fait à North Bay, ce {moment(state.date).format("D")}<sup>e</sup> jour du mois {MONTHS[moment(state.date).format("M") - 1]} {moment(state.date).format("YYYY")}.
                    </p>
                </div>

                <div id="certfooter">
                    <div>
                        <img style={{"left":"51%"}} src={signatureDenisLabelle} className="signature" alt="Signature à Denis Labelle, Président" />
                        <p>
                            {state.serviceTimeSpan.nameSchoolDirection}
                            <br />
                            {state.serviceTimeSpan.titleSchoolDirection}
                        </p>
                    </div>
                    <div>
                        <img className="conseillogo" src={consellogo} alt="Logo" />
                    </div>
                    <div>
                        <img src={signatureYvesLaliberte} className="signature" alt="Signature à Yves Laliberté, Directeur de l'éducation" />
                        <p>
                            {state.serviceTimeSpan.nameEducationDirecteur}
                            <br />
                            {state.serviceTimeSpan.titleEducationDirecteur}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YearsOfServiceCertificate;